<template>
  <div>
        <!-- 顶部工具条 -->
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label='关键字:'>
                        <el-input v-model='filters.condition' placeholder='请输入关键字' clearable @clear='clearContent'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>                           
            </el-col>         
        </el-row>
        <!-- 表格主体 -->
        <el-table
            v-loading='listLoading'
            :data="tableData"
            border
            style="width: 98%"
            highlight-current-row
            @current-change='currentChange'
            :cell-style='rowClass'
            :header-cell-style='headerStyle'
        >   
            <el-table-column type='index' label='序号' width='60'></el-table-column>
            <el-table-column prop="City" label="市"></el-table-column>
            <el-table-column prop="Region" label="区/县"></el-table-column>
            <el-table-column prop="Name" label="培训机构" min-width="120"></el-table-column>
            <el-table-column prop="TrainStudentNumber" label="参训人数" sortable min-width="110"></el-table-column>
            <el-table-column prop="LearnNumberGtZero" label="学习课时大于0人数" sortable min-width="180"></el-table-column>
            <el-table-column prop="LearnNumber" label="学习课时超10%人数" sortable min-width="180"></el-table-column>
            <el-table-column prop="ExamNumber" label="考试人数" sortable min-width="110"></el-table-column>
            <el-table-column prop="PassStudentNumber" label="取证人数" sortable min-width="110"></el-table-column>
            <!-- <el-table-column prop="PassRate" label="取证率" sortable>
                <template scope="scope">
                    {{formation(scope.row.PassRate)}}
                </template>
            </el-table-column> -->
            <el-table-column prop='CertStudentNumber' label='一次性取证人数' sortable min-width="150"></el-table-column>
            <el-table-column prop='CertRate' label='一次性取证率（方式1）' sortable min-width="200">
                <template scope="scope">
                    {{formation(scope.row.CertRate)}}
                </template>
            </el-table-column>
            <el-table-column prop='CertRate' label='一次性取证率（方式2）' sortable min-width="200">
                <template scope="scope">
                    {{formation(scope.row.CerRateNew)}}
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <!-- <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.PageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pages.PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.DataCount"
            class='pageBar'
        >
        </el-pagination> -->
  </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs';
import util from "../../../util/date";
import {getAgencyInfoBriefListPage} from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            filters:{
                condition:''
            },
            tableData:[],
            currentRow:{},
            pages:{
                PageIndex:1,
                PageSize:20,
                DataCount:0
            },
            status:null,
            buttonList:[],
            listLoading:false,
            ProvinceID: 1919,
            CityID: '',
            RegionID: '',
            TownID: '',
        }
    },
    methods:{
        // 列表样式
        rowClass(){
            return 'text-align:center;'
        },
        headerStyle(){
            return 'text-align:center;'
        },
        // 筛选框清空重新请求数据
        clearContent(){
            this.getData()
        },
        // 页面数据
        getData(){
            this.listLoading = true
            var params = {
                name:this.filters.condition,
                provinceID: this.ProvinceID,
                cityID: this.CityID,
                regionID: this.RegionID,
                townID: this.TownID,
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize,
            }
            getAgencyInfoBriefListPage(params).then(res => {
                if(res.data.Success){
                    this.listLoading = false
                    res.data.Response.sort(function (a, b) {
                        return b.CertRate - a.CertRate
                    })
                    this.tableData = res.data.Response
                    // this.pages.DataCount = res.data.Response.DataCount
                    this.currentRow = {}
                }else{
                    this.listLoading = false
                    this.$message.error(res.data.Message)
                }
            })
        },
        // 获取当前行数据
        currentChange(row){
            if(JSON.stringify(row) == '{}'){
                this.$message.error('请选择一行数据')
            }else{
                this.currentRow = row
            }         
        },
        // 分页
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getData()
        },
        // 查询事件
        getTableDataFun(){
            this.pages.PageIndex = 1
            this.getData()
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 时间格式化
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
        // 小数取整
        formation: function(val) {
            if(val && val > 0) {
                return String(val * 100).substr(0,5) + '%'
            } else {
                return 0
            }
        }
    },
    created(){
        this.ProvinceID = this.$route.query.ProvinceID
        this.CityID = this.$route.query.CityID
        this.RegionID = this.$route.query.RegionID
        this.TownID = this.$route.query.TownID
        this.getData()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    // .pageBar{
    //     margin-top:15px!important;
    // }
</style>